<template>
  <div>
    <div
      id="StockPriceWithTrade"
      style="width: 850px"
      :style="{ height: '300px' }"
    ></div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import moment from "moment";

export default {
  name: "BackTest",
  components: {},
  data() {
    return {
      dailyData: null,
      myChart: null,
      pageHeight: 0,
    };
  },
  computed: {},
  mounted() {
    this.pageHeight = document.body.offsetHeight;
    setTimeout(() => {
      this.getData();
    }, 500);
    // Bus.$on("showBackTest", (e) => {
    //   this.getData();
    // });
  },
  methods: {
    getData() {
      this.dailyData = this.appStore.backTestData;

      this.drawLine();
    },
    //绘制回测图
    drawLine() {
      let chartDom = document.getElementById("StockPriceWithTrade");
      this.myChart = this.$echarts.init(chartDom, null, {
        locale: "ZH",
      });

      let dateArr = [];
      let data = [];
      for (let key in this.dailyData.nav.compose) {
        dateArr.push(key);
        data.push([key, this.dailyData.nav.compose[key]]);
      }

      let option = {
        animation: true,
        animationThreshold: 2000,
        animationDuration: 1000,
        animationEasing: "cubicOut",
        animationDelay: 0,
        animationDurationUpdate: 300,
        animationEasingUpdate: "cubicOut",
        animationDelayUpdate: 0,
        aria: {
          enabled: false,
        },
        xAxis: {
          type: "time",
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          boundaryGap: false,
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
          },
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          triggerOn: "mousemove|click",
          axisPointer: {
            type: "cross",
          },
          showContent: true,
          alwaysShowContent: false,
          showDelay: 0,
          hideDelay: 100,
          enterable: false,
          confine: false,
          appendToBody: false,
          transitionDuration: 0.4,
          textStyle: {
            color: "#000",
            fontFamily: "阿里妈妈",
          },
          backgroundColor: "rgba(245, 245, 245, 0.8)",
          borderColor: "#ccc",
          borderWidth: 1,
          padding: 5,
          order: "seriesAsc",
          //显示文字的处理函数
          formatter: function (params) {
            let date = moment(params[0].axisValue).format("YYYY-MM-DD");
            let value = params[0].data[1];
            return `日期: ${date} <br />累计收益: ${(value * 100).toFixed(2)}%`;
          },
        },
        series: [
          {
            name: "累计收益",
            type: "line",
            smooth: false,
            symbol: "none",
            data: data,
            areaStyle: {},
          },
        ],
        visualMap: {
          show: false,
          hoverLink: false,
          type: "piecewise",
          pieces: [
            { lte: -0.0001, color: "#2AA49D", label: "负收益" },
            { gt: -0.0001, lt: 0.0001, color: "transparent", label: " " },
            { gte: 0.0001, color: "#EF534F", label: "正收益" },
          ],
          seriesIndex: 0,
          outOfRange: {
            color: "#6e6e6e",
          },
        },
        grid: [
          {
            show: false,
            zlevel: 0,
            z: 2,
            top: "15%",
            left: "5%",
            right: "2%",
            height: "70%",
            containLabel: false,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            borderWidth: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
        ],
      };

      this.myChart.setOption(option);
    },
  },
};
</script>
