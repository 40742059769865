<template>
  <div>
    <div
      style="width: 100%; font-size: 40px; color: #ccc"
      :style="{ height: pageHeight - 80 + 'px' }"
      class="d-flex justify-center align-center"
      v-if="loading"
    >
      正在载入日线数据
    </div>
    <div
      id="KLineChart"
      style="width: 900px"
      :style="{ height: pageHeight - 80 + 'px' }"
    ></div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import moment from "moment";

export default {
  name: "DailyLine",
  components: {},
  data() {
    return {
      dailyData: null,
      start_date: moment().subtract(2, "years").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      myChart: null,
      pageHeight: 0,
      loading: true,
    };
  },
  computed: {},
  mounted() {
    this.pageHeight = document.body.offsetHeight;
    this.getDailyLine();
  },
  methods: {
    //获取日线数据
    getDailyLine() {
      Stock.getKLine({
        code: this.appStore.currentStockDetail.stock_code,
        type: "hfq",
        start_date: this.start_date,
        end_date: this.end_date,
        has_adj: true,
        code_type: this.appStore.currentStockDetail.code_type,
      })
        .then((res) => {
          //console.log(res);
          this.dailyData = res.data;
          this.drawKLine();
        })
        .catch((err) => {
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //绘制K线图
    drawKLine() {
      let chartDom = document.getElementById("KLineChart");
      this.myChart = this.$echarts.init(chartDom, null, {
        locale: "ZH",
      });

      let barData = [];
      this.dailyData.forEach((item) => {
        barData.unshift([item.open, item.close, item.low, item.high]);
      });
      let vol = [];
      this.dailyData.forEach((item) => {
        vol.unshift((item.vol / 10000).toFixed(2));
      });
      let dateArr = [];
      this.dailyData.forEach((item) => {
        dateArr.unshift(item.trade_date);
      });

      let option = {
        animation: true,
        animationThreshold: 2000,
        animationDuration: 1000,
        animationEasing: "cubicOut",
        animationDelay: 0,
        animationDurationUpdate: 300,
        animationEasingUpdate: "cubicOut",
        animationDelayUpdate: 0,
        aria: {
          enabled: false,
        },
        color: [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
        ],
        series: [
          {
            type: "candlestick",
            name: "日K",
            colorBy: "series",
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: barData,
            itemStyle: {
              color: "#EF534F",
              color0: "#2AA49D",
            },
            label: {
              show: true,
              margin: 8,
            },
            rippleEffect: {
              show: true,
              brushType: "stroke",
              scale: 2.5,
              period: 4,
            },
          },
          {
            type: "bar",
            name: "成交量(万)",
            xAxisIndex: 1,
            yAxisIndex: 1,
            legendHoverLink: true,
            data: vol,
            realtimeSort: false,
            showBackground: false,
            stackStrategy: "samesign",
            cursor: "pointer",
            barMinHeight: 0,
            barCategoryGap: "20%",
            barGap: "30%",
            large: false,
            largeThreshold: 400,
            seriesLayoutBy: "column",
            datasetIndex: 0,
            clip: true,
            zlevel: 0,
            z: 2,
            label: {
              show: false,
              margin: 8,
            },
            itemStyle: {
              color: function (params) {
                var colorList;
                if (
                  barData[params.dataIndex][1] > barData[params.dataIndex][0]
                ) {
                  colorList = "#EF534F";
                } else {
                  colorList = "#2AA49D";
                }
                return colorList;
              },
            },
          },
        ],
        legend: [
          {
            data: ["OHLC"],
            selected: {},
            show: false,
            padding: 5,
            itemGap: 10,
            itemWidth: 25,
            itemHeight: 14,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            borderWidth: 1,
            borderRadius: 0,
            pageButtonItemGap: 5,
            pageButtonPosition: "end",
            pageFormatter: "{current}/{total}",
            pageIconColor: "#2f4554",
            pageIconInactiveColor: "#aaa",
            pageIconSize: 15,
            animationDurationUpdate: 800,
            selector: false,
            selectorPosition: "auto",
            selectorItemGap: 7,
            selectorButtonGap: 10,
            left: "center",
            bottom: 10,
          },
          {
            data: ["成交量(万)"],
            selected: {},
            show: false,
            padding: 5,
            itemGap: 10,
            itemWidth: 25,
            itemHeight: 14,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            borderWidth: 1,
            borderRadius: 0,
            pageButtonItemGap: 5,
            pageButtonPosition: "end",
            pageFormatter: "{current}/{total}",
            pageIconColor: "#2f4554",
            pageIconInactiveColor: "#aaa",
            pageIconSize: 15,
            animationDurationUpdate: 800,
            selector: false,
            selectorPosition: "auto",
            selectorItemGap: 7,
            selectorButtonGap: 10,
          },
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          triggerOn: "mousemove|click",
          axisPointer: {
            type: "cross",
          },
          showContent: true,
          alwaysShowContent: false,
          showDelay: 0,
          hideDelay: 100,
          enterable: false,
          confine: false,
          appendToBody: false,
          transitionDuration: 0.4,
          textStyle: {
            color: "#000",
            fontFamily: "阿里妈妈",
          },
          backgroundColor: "rgba(245, 245, 245, 0.8)",
          borderColor: "#ccc",
          borderWidth: 1,
          padding: 5,
          order: "seriesAsc",
        },
        xAxis: [
          {
            show: true,
            scale: true,
            nameLocation: "end",
            nameGap: 15,
            gridIndex: 0,
            inverse: false,
            offset: 0,
            splitNumber: 5,
            minInterval: 0,
            splitLine: {
              show: true,
              lineStyle: {
                show: true,
                width: 1,
                opacity: 1,
                curveness: 0,
                type: "solid",
              },
            },
            data: dateArr,
          },
          {
            type: "category",
            show: true,
            scale: false,
            nameLocation: "end",
            nameGap: 15,
            gridIndex: 1,
            axisLabel: {
              show: false,
              margin: 8,
            },
            inverse: false,
            offset: 0,
            splitNumber: 5,
            minInterval: 0,
            splitLine: {
              show: true,
              lineStyle: {
                show: true,
                width: 1,
                opacity: 1,
                curveness: 0,
                type: "solid",
              },
            },
            data: dateArr,
          },
        ],
        yAxis: [
          {
            show: true,
            scale: true,
            nameLocation: "end",
            nameGap: 15,
            gridIndex: 0,
            inverse: false,
            offset: 0,
            splitNumber: 5,
            minInterval: 0,
            splitLine: {
              show: true,
              lineStyle: {
                show: true,
                width: 1,
                opacity: 1,
                curveness: 0,
                type: "solid",
              },
            },
            splitArea: {
              show: true,
              areaStyle: {
                opacity: 1,
              },
            },
          },
          {
            show: true,
            scale: false,
            nameLocation: "end",
            nameGap: 15,
            gridIndex: 1,
            inverse: false,
            offset: 0,
            splitNumber: 5,
            minInterval: 0,
            splitLine: {
              show: true,
              lineStyle: {
                show: true,
                width: 1,
                opacity: 1,
                curveness: 0,
                type: "solid",
              },
            },
          },
        ],
        dataZoom: [
          {
            show: false,
            type: "inside",
            showDetail: true,
            showDataShadow: true,
            realtime: true,
            start: 60,
            end: 100,
            orient: "horizontal",
            xAxisIndex: [0, 1],
            zoomLock: false,
            filterMode: "filter",
          },
          {
            show: true,
            type: "slider",
            showDetail: true,
            showDataShadow: true,
            realtime: true,
            start: 60,
            end: 100,
            orient: "horizontal",
            xAxisIndex: [0, 1],
            zoomLock: false,
            top: "93%",
            filterMode: "filter",
          },
        ],
        axisPointer: {
          show: true,
          type: "line",
          link: [
            {
              xAxisIndex: "all",
            },
          ],
          label: {
            show: true,
            margin: 8,
            backgroundColor: "#777",
          },
          triggerTooltip: true,
          triggerOn: "mousemove|click",
        },

        grid: [
          {
            show: false,
            zlevel: 0,
            z: 2,
            top: "5%",
            left: "5%",
            right: "2%",
            height: "65%",
            containLabel: false,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            borderWidth: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
          {
            show: false,
            zlevel: 0,
            z: 2,
            left: "5%",
            top: "75%",
            right: "2%",
            height: "16%",
            containLabel: false,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            borderWidth: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
        ],
      };

      this.myChart.setOption(option);
    },
  },
};
</script>
