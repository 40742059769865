<template>
  <div>
    <h2 class="mt-4 pl-6">策略回测报告</h2>

    <h4 class="mt-2 pl-8 text--secondary">
      业绩基准：<u>{{ this.appStore.backTestData.benchmark }}</u>
      ，回测起始日期：<u>{{ this.appStore.backTestData.start_date }}</u>
      ，回测结束日期：<u>{{ this.appStore.backTestData.end_date }}</u>
    </h4>
    <div class="mt-2 px-6">
      <h3 class="mt-2">回测指标概况(策略表现/基准表现)</h3>

      <div class="mt-2 px-0 d-flex justify-space-between flex-sm-wrap">
        <div
          v-for="item in report_Arr"
          style="width: 200px; font-size: 13px; margin-bottom: 1px"
          class="d-flex justify-space-between py-1"
        >
          <span class="text--secondary"> {{ item }} </span>
          <span
            ><span
              style="font-size: 14px"
              :style="
                $stockHelpers.getColor(
                  appStore.backTestData.report_table['策略'][item]
                )
              "
            >
              {{
                $stockHelpers.getPercent(
                  appStore.backTestData.report_table["策略"][item]
                )
              }} </span
            >/
            <span
              style="font-size: 11px"
              :style="
                $stockHelpers.getColor(
                  appStore.backTestData.report_table['benchmark'][item]
                )
              "
            >
              {{
                $stockHelpers.getPercent(
                  appStore.backTestData.report_table["benchmark"][item]
                )
              }}
            </span></span
          >
        </div>
      </div>
    </div>

    <TradeChart></TradeChart>

    <div class="mt-4 px-6">
      <div class="d-flex justify-space-between">
        <h3 class="my-2">交易详情</h3>
        <!-- <div>
          <span style="font-size: 14px" class="mr-2 text-secondary"
            >筛选股票</span
          >
          <vxe-select
            v-model="tradeRecordSelect"
            placeholder="筛选股票"
            size="mini"
          >
            <vxe-option
              v-for="item in [{ stock_code: 'all', stock_name: '全部' }].concat(
                appStore.currentFirmDetail.stockList
              )"
              :key="item.stock_code"
              :value="item.stock_code"
              :label="`${item.stock_name}(${item.stock_code})`"
            >
            </vxe-option>
          </vxe-select>
        </div> -->
      </div>

      <vxe-table
        ref="firmTradeTable"
        border
        show-header-overflow
        show-overflow
        highlight-hover-row
        :data="tradeTable"
        size="mini"
        stripe
        max-height="400"
        :filter-config="{ showIcon: false }"
        :sort-config="{
          trigger: 'cell',
          defaultSort: { field: 'datein', order: 'desc' },
        }"
      >
        <vxe-table-column type="seq" title="序号" width="50"></vxe-table-column>

        <vxe-table-column
          field="stock_code"
          title="股票"
          width="180"
          :filters="[{ data: '' }]"
          :filter-method="
            (value) => $tableHelpers.filterInputMethod(value, 'stock_code')
          "
        >
          <template #filter="{ $panel, column }">
            <template v-for="(option, index) in column.filters">
              <input
                class="my-input"
                type="type"
                :key="index"
                v-model="option.data"
                @input="$panel.changeOption($event, !!option.data, option)"
                @keyup.enter="$panel.confirmFilter()"
                placeholder="按回车确认筛选"
              />
            </template>
          </template>
          <template #default="{ row }">
            {{ row.stock_name }}({{ row.stock_code }})
          </template>
        </vxe-table-column>

        <vxe-table-column field="status" title="状态" width="50" sortable>
          <template #default="{ row }">
            <div :style="{ color: row.status == 1 ? '#FFB02E' : '#606266' }">
              {{ row.status == 1 ? "持有" : "已平仓" }}
            </div>
          </template>
        </vxe-table-column>

        <vxe-table-column
          field="datein"
          title="买入时间"
          width="80"
          sortable
        ></vxe-table-column>
        <vxe-table-column field="pricein" title="买入价" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.pricein ? row.pricein.toFixed(2) : "-" }}
            </div>
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="dateout"
          title="卖出时间"
          width="80"
          sortable
        ></vxe-table-column>
        <vxe-table-column field="priceout" title="卖出价格" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.priceout ? row.priceout.toFixed(2) : "-" }}
            </div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="returns" title="收益率" sortable>
          <template #default="{ row }">
            <div
              class="d-flex justify-end"
              :style="$stockHelpers.getColor(row['returns'])"
            >
              {{ (row["returns"] * 100).toFixed(2) }}%
            </div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="nbars" title="持有天数" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row["nbars"] ? row["nbars"] : "持有中" }}
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>

      <div v-if="tradeRecordSelectData">
        <!-- 净值曲线 -->
        <h3 class="my-2">成分股交易图</h3>
        <div>
          <StockPriceWithTrade
            :stockData="tradeRecordSelectData"
          ></StockPriceWithTrade>
        </div>
      </div>

      <div>
        <!-- 净值曲线 -->
        <h3 class="my-2">策略累计收益图</h3>
        <NetValue></NetValue>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import TradeChart from "@/components/StockDetail/multiBacktest/TradeChart.vue";
import NetValue from "@/components/StockDetail/multiBacktest/NetValue.vue";
import StockPriceWithTrade from "@/components/StockDetail/multiBacktest/StockPriceWithTrade.vue";

export default {
  name: "MultiBackTest",
  components: { NetValue, TradeChart, StockPriceWithTrade },
  data() {
    return {
      report_Arr: [
        "累计收益",
        "年化收益率",
        "波动率",
        "夏普",
        "最大回撤",
        "索提诺比率",
        "Calmar",
        "IR",
        "Alpha",
        "Beta",
      ],
      tradeInfo: [],
      tradeTable: [],

      tradeRecordSelect: "all",
      tradeRecordSelectData: null,
    };
  },
  computed: {},
  mounted() {
    let fields = [];
    let tradeList = [];
    for (let item in this.appStore.backTestData.round_trip[0]) {
      fields.push(item);
    }
    this.appStore.backTestData.round_trip.forEach((stock, stockIndex) => {
      //初始化个股的某一条交易记录
      //console.log(this.appStore.currentFirmDetail.stockList);
      stock.datein.forEach((date, dateIndex) => {
        let tradeRow = {
          stock_code: stock.code,
          stock_name: this.appStore.currentStockDetail.stock_name,
        };
        fields.forEach((field) => {
          tradeRow[field] = [];
        });
        fields.forEach((field) => {
          tradeRow[field] = stock[field][dateIndex];
        });
        tradeList.push(tradeRow);
      });
    });
    this.tradeTable = tradeList;
  },
  methods: {},
  watch: {
    tradeRecordSelect(newValue) {
      console.log("tradeRecordSelect:" + newValue);
      if (newValue == "all") {
        this.tradeRecordSelectData = null;
        setTimeout(() => {
          this.$tableHelpers.filterInput(
            this.$refs.firmTradeTable,
            "stock_code",
            ""
          );
        }, 300);
      } else {
        this.tradeRecordSelectData = this.tradeTable.filter(
          (item) => item.stock_code == newValue
        );
        //console.log(this.tradeRecordSelectData);
        setTimeout(() => {
          this.$tableHelpers.filterInput(
            this.$refs.firmTradeTable,
            "stock_code",
            newValue
          );
        }, 300);
      }
    },
  },
};
</script>
