<template>
  <div>
    <div class="pl-6 mt-4" style="font-size: 12px">
      <span>图例</span>
      <span class="ml-2" style="color: #ef534f">
        <svg
          t="1695797171390"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3051"
          width="12"
          height="12"
        >
          <path
            d="M535.466667 812.8l450.133333-563.2c14.933333-19.2 2.133333-49.066667-23.466667-49.066667H61.866667c-25.6 0-38.4 29.866667-23.466667 49.066667l450.133333 563.2c12.8 14.933333 34.133333 14.933333 46.933334 0z"
            fill="#ef534f"
            p-id="3052"
          ></path>
        </svg>
        买入
      </span>

      <span class="ml-2" style="color: #2aa49d">
        <svg
          t="1695797171390"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3051"
          width="12"
          height="12"
        >
          <path
            d="M535.466667 812.8l450.133333-563.2c14.933333-19.2 2.133333-49.066667-23.466667-49.066667H61.866667c-25.6 0-38.4 29.866667-23.466667 49.066667l450.133333 563.2c12.8 14.933333 34.133333 14.933333 46.933334 0z"
            fill="#2AA49D"
            p-id="3052"
          ></path>
        </svg>
        卖出
      </span>

      <span class="ml-2" style="color: #ffb02e">
        <svg
          t="1695797171390"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3051"
          width="12"
          height="12"
        >
          <path
            d="M535.466667 812.8l450.133333-563.2c14.933333-19.2 2.133333-49.066667-23.466667-49.066667H61.866667c-25.6 0-38.4 29.866667-23.466667 49.066667l450.133333 563.2c12.8 14.933333 34.133333 14.933333 46.933334 0z"
            fill="#FFB02E"
            p-id="3052"
          ></path>
        </svg>
        持有
      </span>
    </div>
    <div
      id="BackKLineChart"
      style="width: 900px"
      :style="{ height: pageHeight - 400 + 'px' }"
    ></div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import moment from "moment";

export default {
  name: "BackTest",
  components: {},
  data() {
    return {
      dailyData: null,
      myChart: null,
      pageHeight: 0,
    };
  },
  computed: {},
  mounted() {
    this.pageHeight = document.body.offsetHeight;
    setTimeout(() => {
      this.getData();
    }, 500);
    // Bus.$on("showBackTest", (e) => {
    //   this.getData();
    // });
  },
  methods: {
    getData() {
      this.dailyData = this.appStore.backTestData;

      this.drawKLine();
    },
    //绘制回测K线图
    drawKLine() {
      let chartDom = document.getElementById("BackKLineChart");
      this.myChart = this.$echarts.init(chartDom, null, {
        locale: "ZH",
      });

      let dateArr = [];
      let barData = [];
      let markPointData = [];
      let buyArr = [];
      let saleArr = [];
      let dayIndex = 0;
      let tradeIndex = 0;
      let vol = [];
      for (let key in this.dailyData.kline.close) {
        dateArr.push(key);
        barData.push([
          this.dailyData.kline.open[key],
          this.dailyData.kline.close[key],
          this.dailyData.kline.low[key],
          this.dailyData.kline.high[key],
        ]);
        vol.push((this.dailyData.kline.volume[key] / 10000).toFixed(2));
        //买卖标记判断
        if (this.dailyData.trade_record.datein.indexOf(key) != -1) {
          markPointData.push({
            type: "Buy",
            coord: [dayIndex, this.dailyData.kline.low[key] * (1 - 0.02)],
            symbol: "triangle",
            symbolSize: 12,
            itemStyle: {
              color: "#EF534F",
            },
          });
          buyArr.push({
            date: key,
            price:
              this.dailyData.trade_record.pricein[
                this.dailyData.trade_record.datein.indexOf(key)
              ],
          });
        }
        if (this.dailyData.trade_record.dateout.indexOf(key) != -1) {
          let color = "#2AA49D";
          if (
            this.appStore.backTestData.trade_res["交易统计"]["未交易完结笔数"] >
              0 &&
            tradeIndex + 1 ==
              this.appStore.backTestData.trade_res["交易统计"]["交易总笔数"]
          ) {
            color = "#FFB02E";
          }
          markPointData.push({
            type: "Sell",
            coord: [dayIndex, this.dailyData.kline.high[key] * (1 + 0.02)],
            symbol: "triangle",
            symbolSize: 12,
            symbolRotate: 180,
            itemStyle: {
              color: color,
            },
          });
          saleArr.push({
            date: key,
            price:
              this.dailyData.trade_record.priceout[
                this.dailyData.trade_record.dateout.indexOf(key)
              ],
          });
          tradeIndex++;
        }
        dayIndex++;
      }

      let markAreaData = [];
      buyArr.forEach((item, index) => {
        // console.log(saleArr[index], index);
        let color;
        if (buyArr.length == index + 1) {
          if (
            this.appStore.backTestData.trade_res["交易统计"]["未交易完结笔数"] >
            0
          ) {
            color = "#FFB02E";
          } else {
            if (item.price < saleArr[index].price) {
              color = "#EF534F";
            } else {
              color = "#2AA49D";
            }
          }
        } else {
          if (item.price < saleArr[index].price) {
            color = "#EF534F";
          } else {
            color = "#2AA49D";
          }
        }

        let getTradeColor = (val) => {
          let tradeColor = "#EF534F";
          if (val < 0) {
            tradeColor = "#2AA49D";
          } else if (val === 0) {
            tradeColor = "#999999";
          }
          return tradeColor;
        };

        markAreaData.push([
          {
            coord: [item.date, item.price],
            itemStyle: {
              color: color,
              opacity: 0.2,
            },
            tooltip: {
              show: true,
              trigger: "item",
              showContent: true,
              alwaysShowContent: false,
              showDelay: 0,
              hideDelay: 100,
              enterable: false,
              confine: false,
              appendToBody: false,
              transitionDuration: 0.4,
              textStyle: {
                color: "#000",
                fontFamily: "阿里妈妈",
              },
              backgroundColor: "rgba(245, 245, 245, 0.8)",
              borderColor: "#ccc",
              borderWidth: 1,
              padding: 5,
              order: "seriesAsc",
              formatter: `
              <div>买入价: ${item.price.toFixed(2)} (${item.date})</div>
              <div>卖出价: ${
                saleArr[index].price
                  ? saleArr[index].price.toFixed(2)
                  : "未卖出"
              } (${saleArr[index].date})</div>
              <div>持有天数: ${
                this.appStore.backTestData.trade_record["nbars"][index]
                  ? this.appStore.backTestData.trade_record["nbars"][index]
                  : "持有中"
              }</div>
              <div>收益率: <span style="color:${getTradeColor(
                this.appStore.backTestData.trade_record["returns"][index]
              )}">${(
                this.appStore.backTestData.trade_record["returns"][index] * 100
              ).toFixed(2)}%</span></div>
              `,
            },
          },
          {
            coord: [saleArr[index].date, saleArr[index].price],
          },
        ]);
      });
      //console.log(markAreaData);

      let option = {
        animation: true,
        animationThreshold: 2000,
        animationDuration: 1000,
        animationEasing: "cubicOut",
        animationDelay: 0,
        animationDurationUpdate: 300,
        animationEasingUpdate: "cubicOut",
        animationDelayUpdate: 0,
        aria: {
          enabled: false,
        },
        color: [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
        ],
        series: [
          {
            type: "candlestick",
            name: "日K",
            colorBy: "series",
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: barData,
            itemStyle: {
              color: "transparent",
              color0: "#2AA49D",
              borderColor: "#EF534F",
              borderColor0: "#2AA49D",
            },
            label: {
              show: true,
              margin: 8,
            },
            rippleEffect: {
              show: true,
              brushType: "stroke",
              scale: 2.5,
              period: 4,
            },
            markPoint: {
              label: {
                show: true,
                position: "inside",
                color: "#fff",
                margin: 8,
                formatter: function (param) {
                  return "";
                },
              },
              data: markPointData,
            },
            markArea: {
              data: markAreaData,
            },
          },
          {
            type: "bar",
            name: "成交量(万)",
            xAxisIndex: 1,
            yAxisIndex: 1,
            legendHoverLink: true,
            data: vol,
            realtimeSort: false,
            showBackground: false,
            stackStrategy: "samesign",
            cursor: "pointer",
            barMinHeight: 0,
            barCategoryGap: "20%",
            barGap: "30%",
            large: false,
            largeThreshold: 400,
            seriesLayoutBy: "column",
            datasetIndex: 0,
            clip: true,
            zlevel: 0,
            z: 2,
            label: {
              show: false,
              margin: 8,
            },
            itemStyle: {
              color: function (params) {
                var colorList;
                if (
                  barData[params.dataIndex][1] > barData[params.dataIndex][0]
                ) {
                  colorList = "#EF534F";
                } else {
                  colorList = "#2AA49D";
                }
                return colorList;
              },
            },
          },
        ],
        legend: [
          {
            data: ["OHLC"],
            selected: {},
            show: false,
            padding: 5,
            itemGap: 10,
            itemWidth: 25,
            itemHeight: 14,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            borderWidth: 1,
            borderRadius: 0,
            pageButtonItemGap: 5,
            pageButtonPosition: "end",
            pageFormatter: "{current}/{total}",
            pageIconColor: "#2f4554",
            pageIconInactiveColor: "#aaa",
            pageIconSize: 15,
            animationDurationUpdate: 800,
            selector: false,
            selectorPosition: "auto",
            selectorItemGap: 7,
            selectorButtonGap: 10,
            left: "center",
            bottom: 10,
          },
          {
            data: ["成交量(万)"],
            selected: {},
            show: false,
            padding: 5,
            itemGap: 10,
            itemWidth: 25,
            itemHeight: 14,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            borderWidth: 1,
            borderRadius: 0,
            pageButtonItemGap: 5,
            pageButtonPosition: "end",
            pageFormatter: "{current}/{total}",
            pageIconColor: "#2f4554",
            pageIconInactiveColor: "#aaa",
            pageIconSize: 15,
            animationDurationUpdate: 800,
            selector: false,
            selectorPosition: "auto",
            selectorItemGap: 7,
            selectorButtonGap: 10,
          },
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          triggerOn: "mousemove|click",
          axisPointer: {
            type: "cross",
          },
          showContent: true,
          alwaysShowContent: false,
          showDelay: 0,
          hideDelay: 100,
          enterable: false,
          confine: false,
          appendToBody: false,
          transitionDuration: 0.4,
          textStyle: {
            color: "#000",
            fontFamily: "阿里妈妈",
          },
          backgroundColor: "rgba(245, 245, 245, 0.8)",
          borderColor: "#ccc",
          borderWidth: 1,
          padding: 5,
          order: "seriesAsc",
        },
        xAxis: [
          {
            show: true,
            scale: true,
            nameLocation: "end",
            nameGap: 15,
            gridIndex: 0,
            inverse: false,
            offset: 0,
            splitNumber: 5,
            minInterval: 0,
            splitLine: {
              show: true,
              lineStyle: {
                show: true,
                width: 1,
                opacity: 1,
                curveness: 0,
                type: "solid",
              },
            },
            data: dateArr,
          },
          {
            type: "category",
            show: true,
            scale: false,
            nameLocation: "end",
            nameGap: 15,
            gridIndex: 1,
            axisLabel: {
              show: false,
              margin: 8,
            },
            inverse: false,
            offset: 0,
            splitNumber: 5,
            minInterval: 0,
            splitLine: {
              show: true,
              lineStyle: {
                show: true,
                width: 1,
                opacity: 1,
                curveness: 0,
                type: "solid",
              },
            },
            data: dateArr,
          },
        ],
        yAxis: [
          {
            show: true,
            scale: true,
            nameLocation: "end",
            nameGap: 15,
            gridIndex: 0,
            inverse: false,
            offset: 0,
            splitNumber: 5,
            minInterval: 0,
            splitLine: {
              show: true,
              lineStyle: {
                show: true,
                width: 1,
                opacity: 1,
                curveness: 0,
                type: "solid",
              },
            },
            splitArea: {
              show: true,
              areaStyle: {
                opacity: 1,
              },
            },
          },
          {
            show: true,
            scale: false,
            nameLocation: "end",
            nameGap: 15,
            gridIndex: 1,
            inverse: false,
            offset: 0,
            splitNumber: 5,
            minInterval: 0,
            splitLine: {
              show: true,
              lineStyle: {
                show: true,
                width: 1,
                opacity: 1,
                curveness: 0,
                type: "solid",
              },
            },
          },
        ],
        dataZoom: [
          {
            show: false,
            type: "inside",
            showDetail: true,
            showDataShadow: true,
            realtime: true,
            start: 60,
            end: 100,
            orient: "horizontal",
            xAxisIndex: [0, 1],
            zoomLock: false,
            filterMode: "filter",
          },
          {
            show: true,
            type: "slider",
            showDetail: true,
            showDataShadow: true,
            realtime: true,
            start: 60,
            end: 100,
            orient: "horizontal",
            xAxisIndex: [0, 1],
            zoomLock: false,
            top: "93%",
            filterMode: "filter",
          },
        ],
        axisPointer: {
          show: true,
          type: "line",
          link: [
            {
              xAxisIndex: "all",
            },
          ],
          label: {
            show: true,
            margin: 8,
            backgroundColor: "#777",
          },
          triggerTooltip: true,
          triggerOn: "mousemove|click",
        },

        grid: [
          {
            show: false,
            zlevel: 0,
            z: 2,
            top: "5%",
            left: "5%",
            right: "2%",
            height: "65%",
            containLabel: false,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            borderWidth: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
          {
            show: false,
            zlevel: 0,
            z: 2,
            left: "5%",
            top: "75%",
            right: "2%",
            height: "16%",
            containLabel: false,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            borderWidth: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          },
        ],
      };

      this.myChart.setOption(option);
    },
  },
};
</script>
