<template>
  <div>
    <h2 class="mt-4 pl-6">策略回测报告</h2>
    <h3 class="mt-2 pl-6">策略信息</h3>
    <h4 class="mt-2 pl-8 text--secondary">
      您使用的是
      <span
        style="color: #ffffff; background-color: #ef534f; border-radius: 5px"
        class="px-2 py-1"
        >{{
          this.appStore.backTestData.method
            ? this.appStore.backTestData.method
            : this.appStore.backTestData.name
        }}</span
      >
      ，回测标的为
      <span
        style="color: #ffffff; background-color: #ef534f; border-radius: 5px"
        class="px-2 py-1"
        >{{ this.appStore.backTestData.stock_name }}({{
          this.appStore.backTestData.stock_code
        }})</span
      >
    </h4>
    <h4 class="mt-2 pl-8 text--secondary">
      参数：
      <span v-for="(item, index) in this.appStore.backTestData.options">
        {{ index }}:<u>{{ item }}</u> ，
      </span>

      回测起始日期：<u>{{ this.appStore.backTestData.start_date }}</u>
      ，回测结束日期：<u>{{ this.appStore.backTestData.end_date }}</u>
    </h4>
    <div class="mt-2 px-6">
      <h3 class="mt-2">回测指标概况</h3>

      <div class="mt-2 px-0 d-flex justify-space-between flex-sm-wrap">
        <div
          v-for="item in report_Arr"
          style="width: 166px; font-size: 13px; margin-bottom: 1px"
          class="d-flex justify-space-between py-1 px-4"
        >
          <span class="text--secondary"> {{ item.name }} </span>
          <span :style="$stockHelpers.getColor(item.value)">
            {{ $stockHelpers.getPercent(item.value) }}
          </span>
        </div>
      </div>
    </div>
    <KLine></KLine>
    <div class="mt-2 px-6">
      <h3 class="mt-2">交易统计</h3>

      <div class="mt-2 px-0 d-flex justify-space-between flex-sm-wrap">
        <div
          v-for="item in tradeInfo"
          style="width: 166px; font-size: 13px; margin-bottom: 1px"
          class="d-flex justify-space-between py-1 px-4"
        >
          <span class="text--secondary"> {{ item.name }} </span>
          <span
            :style="$stockHelpers.getColor(item.value)"
            v-if="
              item.name.indexOf('比') != -1 || item.name.indexOf('率') != -1
            "
          >
            {{ $stockHelpers.getPercent(item.value) }}
          </span>
          <span
            class="text--dark"
            v-if="
              item.name.indexOf('比') == -1 && item.name.indexOf('率') == -1
            "
          >
            {{ item.value }}
          </span>
        </div>
      </div>
    </div>

    <div class="mt-2 px-6">
      <h3 class="my-2">交易详情</h3>
      <vxe-table
        border
        show-header-overflow
        show-overflow
        highlight-hover-row
        :data="tradeTable"
        size="mini"
        stripe
        max-height="400"
      >
        <vxe-table-column type="seq" title="序号" width="50"></vxe-table-column>
        <vxe-table-column field="status" title="状态" width="50" sortable>
          <template #default="{ row }">
            <div :style="{ color: row.status == 1 ? '#FFB02E' : '#606266' }">
              {{ row.status == 1 ? "持有" : "已平仓" }}
            </div>
          </template>
        </vxe-table-column>
        <!-- <vxe-table-column field="dir" title="买入方向"></vxe-table-column> -->
        <vxe-table-column
          field="datein"
          title="买入时间"
          width="80"
          sortable
        ></vxe-table-column>
        <vxe-table-column field="pricein" title="买入价" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.pricein ? row.pricein.toFixed(2) : "-" }}
            </div>
          </template>
        </vxe-table-column>
        <vxe-table-column
          field="dateout"
          title="卖出时间"
          width="80"
          sortable
        ></vxe-table-column>
        <vxe-table-column field="priceout" title="卖出价格" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row.priceout ? row.priceout.toFixed(2) : "-" }}
            </div>
          </template>
        </vxe-table-column>
        <vxe-table-column field="returns" title="收益率" sortable>
          <template #default="{ row }">
            <div
              class="d-flex justify-end"
              :style="$stockHelpers.getColor(row['returns'])"
            >
              {{ (row["returns"] * 100).toFixed(2) }}%
            </div>
          </template>
        </vxe-table-column>
        <!-- <vxe-table-column field="pnl" title="收益"></vxe-table-column> -->
        <!-- <vxe-table-column field="pnl" title="收益率(扣费)" sortable>
          <template #default="{ row }">
            <div
              class="d-flex justify-end"
              :style="$stockHelpers.getColor(row['pnl'])"
            >
              {{ row["pnl"].toFixed(2) }}%
            </div>
          </template>
        </vxe-table-column> -->
        <!-- <vxe-table-column field="size" title="持仓量">
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row["size"] }}
            </div>
          </template>
        </vxe-table-column> -->
        <!-- <vxe-table-column field="value" title="市值"></vxe-table-column> -->
        <!-- <vxe-table-column field="cumpnl" title="cumpnl"></vxe-table-column> -->
        <vxe-table-column field="nbars" title="持有天数" sortable>
          <template #default="{ row }">
            <div class="d-flex justify-end">
              {{ row["nbars"] ? row["nbars"] : "持有中" }}
            </div>
          </template>
        </vxe-table-column>
        <!-- <vxe-table-column field="pnl/bar" title="pnl/bar"></vxe-table-column> -->
        <!-- <vxe-table-column field="mfe%" title="mfe%"></vxe-table-column>
        <vxe-table-column field="mae%" title="mae%"></vxe-table-column> -->
      </vxe-table>

      <div>
        <!-- 净值曲线 -->
        <h3 class="my-2">策略累计收益图</h3>
        <NetValue></NetValue>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import moment from "moment";
import KLine from "@/components/StockDetail/backTest/KLine.vue";
import NetValue from "@/components/StockDetail/backTest/NetValue.vue";
import { color } from "d3";

export default {
  name: "BackTest",
  components: { KLine, NetValue },
  data() {
    return {
      report_Arr: [
        {
          name: "累计收益",
          value: this.appStore.backTestData.report_table["策略"]["累计收益"],
        },
        {
          name: "年化收益率",
          value: this.appStore.backTestData.report_table["策略"]["年化收益率"],
        },
        {
          name: "波动率",
          value: this.appStore.backTestData.report_table["策略"]["波动率"],
        },
        {
          name: "夏普",
          value: this.appStore.backTestData.report_table["策略"]["夏普"],
        },
        {
          name: "最大回撤",
          value: this.appStore.backTestData.report_table["策略"]["最大回撤"],
        },

        {
          name: "索提诺比率",
          value: this.appStore.backTestData.report_table["策略"]["索提诺比率"],
        },
        {
          name: "Calmar",
          value: this.appStore.backTestData.report_table["策略"]["Calmar"],
        },
        {
          name: "IR",
          value: this.appStore.backTestData.report_table["策略"]["IR"],
        },
        {
          name: "Alpha",
          value: this.appStore.backTestData.report_table["策略"]["Alpha"],
        },
        {
          name: "Beta",
          value: this.appStore.backTestData.report_table["策略"]["Beta"],
        },
      ],
      tradeInfo: [],
      tradeTable: [],
    };
  },
  computed: {},
  mounted() {
    for (let item in this.appStore.backTestData.trade_res["交易统计"]) {
      this.tradeInfo.push({
        name: item,
        value: this.appStore.backTestData.trade_res["交易统计"][item],
      });
    }
    let fields = [];
    for (let item in this.appStore.backTestData.trade_record) {
      fields.push(item);
    }
    this.appStore.backTestData.trade_record["datein"].forEach((item, index) => {
      let obj = {};
      fields.forEach((field) => {
        obj[field] = this.appStore.backTestData.trade_record[field][index];
      });
      this.tradeTable.push(obj);
    });
    //console.log(this.tradeTable);
  },
  methods: {},
};
</script>
