<template>
  <div class="mx-2">
    <div class="mt-2">
      <vxe-button
        class="px-1 py-0"
        status="my-primary"
        @click="addStrategy()"
        size="mini"
        content="添加策略"
      ></vxe-button>
      <span class="text--secondary pl-2" style="font-size: 12px"
        >可添加多个策略混合</span
      >
    </div>
    <div class="mt-2">
      <vxe-table
        id="strategyTable"
        row-id="id"
        ref="strategyTable"
        :data="strategyArr"
        :border="false"
        resizable
        show-overflow
        show-header-overflow
        highlight-hover-row
        align="left"
        :column-config="{ resizable: true }"
        height="500px"
        size="small"
        :sort-config="{ trigger: 'cell', multiple: true }"
        :empty-render="{ name: 'NotData' }"
        :filter-config="{ showIcon: false }"
        :expand-config="{ trigger: 'row' }"
      >
        <vxe-column min-width="70" field="code" title="策略名" type="expand">
          <template #default="{ row }">
            {{ row.method ? row.method : row.name }}
          </template>
          <template v-slot:content="{ row, rowIndex }">
            <div class="pa-2" style="">
              <div v-for="(item, index) in row.options">
                {{ index }} : {{ item }}
              </div>
            </div>
          </template>
        </vxe-column>

        <vxe-column
          title="操作"
          type="seq"
          width="80"
          :resizable="false"
          show-overflow
        >
          <template #default="{ row }">
            <div>
              <a
                href="javascript:;"
                @click="deleteStrategy(row)"
                class="text-xs font-weight-normal text-typo"
                >删除</a
              >
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div>
      <div class="my-1 d-flex justify-space-between align-center">
        <span style="font-size: 13px" class="mr-2">回测起始日期</span>
        <vxe-input
          v-model="start_date"
          placeholder="回测起始日期"
          type="date"
          size="mini"
        ></vxe-input>
      </div>
      <div class="my-1 d-flex justify-space-between align-center">
        <span style="font-size: 13px" class="mr-2">回测结束日期</span>
        <vxe-input
          v-model="end_date"
          placeholder="回测结束日期"
          type="date"
          size="mini"
        ></vxe-input>
      </div>
      <vxe-button
        class="px-1 py-0 my-2"
        status="primary"
        content="开始回测"
        @click="submit()"
      ></vxe-button>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import StrategyAdd from "@/components/Firm/StrategyAdd.vue";
import moment from "moment";

export default {
  name: "Strategy",
  components: {},
  props: {},
  data() {
    return {
      stockList: [
        {
          stock_code: this.appStore.currentStockDetail.stock_code,
          type: this.appStore.currentStockDetail.code_type,
        },
      ],
      strategyArr: [],
      start_date: moment().subtract(2, "years").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {},

  mounted() {
    Bus.$on("emitStrategyForm", (data) => {
      this.showToast("添加成功", "success", 3000);
      this.strategyArr.push(data);
    });
  },
  methods: {
    submit() {
      if (this.strategyArr.length == 0) {
        this.showToast("请添加至少一个策略", "warning", 3000);
        return;
      }
      if (this.stockList.length == 0) {
        this.showToast("请添加至少一个股票", "warning", 3000);
        return;
      }
      let data = [];
      this.strategyArr.forEach((strategy) => {
        this.stockList.forEach((stock) => {
          strategy = { ...strategy, ...strategy.options };
          delete strategy.options;
          delete strategy.name;
          data.push({
            ...strategy,
            start_date: this.start_date,
            end_date: this.end_date,
            code: stock.stock_code,
            code_type: stock.type,
          });
        });
      });
      console.log(data);
      this.showLoading("回测中，请稍候");
      Stock.multiStrategy(data)
        .then((res) => {
          this.$commonHelpers.showLog({}, this);
          this.handelLog(res.data.task_id);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 10000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    handelLog(id) {
      let timer = null;
      this.appStore.logData = "";
      const getLog = () => {
        console.log("handelLog:" + id);
        Stock.getLog(id)
          .then((res) => {
            console.log(res);
            this.$commonHelpers.handelLog(res, this);
            if (res.data.status === 0) {
              console.log("正在回测中");
            }

            if (res.data.status === -1) {
              clearInterval(timer);
              this.showToast("回测失败，服务器异常！", "danger", 5000);
            }
            if (res.data.status === 1) {
              clearInterval(timer);
              this.showToast("回测成功！", "success", 5000);
              this.appStore.backTestData = {
                ...res.data.content.data,
                start_date: this.start_date,
                end_date: this.end_date,
                benchmark: "沪深300",
              };
              Bus.$emit("showMultiBackTest", true);
              this.appStore.logData += `<span style='color: #2AA49D'>| INFO  |</span>回测已经成功，日志将在三秒后自动关闭！`;
              setTimeout(() => {
                Bus.$emit("closeLogWindow", true);
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getLog();
      timer = setInterval(() => {
        getLog();
      }, 1000);
    },
    addStrategy() {
      this.$layer.iframe({
        content: {
          content: StrategyAdd,
          parent: this,
          data: { iframeData: {} },
        },
        area: ["600px", "600px"],
        title: "添加策略",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    deleteStrategy(row) {
      console.log(row);
      this.strategyArr = this.strategyArr.filter((item) => {
        //过滤strategyId、method、options完全相同的策略，method可能为空
        if (item.id == row.id) {
          if (item.method) {
            if (item.method == row.method) {
              if (JSON.stringify(item.options) == JSON.stringify(row.options)) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {
            if (JSON.stringify(item.options) == JSON.stringify(row.options)) {
              return false;
            } else {
              return true;
            }
          }
        } else {
          return true;
        }
      });
    },
  },
};
</script>
