import Request from "@/components/request/js/index.js";

export default {
  //股票池列表
  getPoolList() {
    return Request().get("/api/pools", {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //删除股票
  deleteFromPool(code) {
    return Request().post(`/api/pools/${code}/delete`, {
      header: {
        contentType: "application/json",
      },
      data: {},
    });
  },
  //添加
  addToPool(code, type) {
    return Request().post(`/api/pools`, {
      header: {
        contentType: "application/json",
      },
      data: {
        code: code,
        type: type,
      },
    });
  },
  //是否入池
  isPool(code) {
    return Request().post(`/api/pools/check`, {
      header: {
        contentType: "application/json",
      },
      data: {
        code: code,
      },
    });
  },
};
