<template>
  <v-container fluid class="px-1 py-2">
    <div
      style="width: calc(100vw - 90px); display: flex; justify-content: center"
      v-if="showPage"
    >
      <div style="width: 1280px; background-color: #fff">
        <div style="width: 100%; display: flex">
          <div style="width: 900px; border: 1px solid #ccc">
            <DailyLine
              v-show="!showBackTest && !showMultiBackTest"
              key="dayliline"
            ></DailyLine>
            <BackTest v-if="showBackTest" key="backtest"></BackTest>
            <MultiBackTest
              v-if="showMultiBackTest"
              key="MultiBackTest"
            ></MultiBackTest>
          </div>
          <div style="width: 380px; border: 1px solid #ccc">
            <!-- 股票信息 -->
            <div class="d-flex justify-space-between align-center px-2 pt-4">
              <div>
                <span class="text-secondary mr-2" style="font-size: 14px">{{
                  appStore.currentStockDetail.stock_code
                }}</span>
                <span class="text-dark">{{
                  appStore.currentStockDetail.stock_name
                }}</span>
              </div>
              <div>
                <vxe-button
                  v-if="!inPool"
                  class="px-1 py-0"
                  status="my-primary"
                  @click="addToPool()"
                  size="mini"
                  content="加自选"
                ></vxe-button>
                <vxe-button
                  v-if="inPool"
                  class="px-1 py-0"
                  status="my-danger"
                  @click="deleteFromPool()"
                  size="mini"
                  content="删自选"
                ></vxe-button>
              </div>
            </div>
            <div class="px-1 pb-2">
              <vxe-radio-group v-model="main_tab" size="mini" class="mt-2">
                <vxe-radio-button
                  label="1"
                  content="策略回测"
                ></vxe-radio-button>
                <vxe-radio-button
                  label="2"
                  content="形态识别"
                ></vxe-radio-button>
                <vxe-radio-button
                  label="3"
                  content="资料仓库"
                ></vxe-radio-button>
              </vxe-radio-group>
              <div class="mt-2">
                <v-slide-x-transition group hide-on-leave>
                  <!-- 策略回测 -->
                  <div
                    v-if="main_tab == 1"
                    class="pa-1"
                    style="border: 1px solid #ccc"
                    key="main_tab1"
                  >
                    <Strategy></Strategy>
                  </div>

                  <!-- 形态识别 -->
                  <div
                    v-if="main_tab == 2"
                    class="pa-1"
                    style="border: 1px solid #ccc"
                    key="main_tab2"
                  >
                    形态识别暂未开放
                  </div>

                  <!-- 资料仓库 -->
                  <div
                    v-if="main_tab == 3"
                    class="pa-1"
                    style="border: 1px solid #ccc"
                    key="main_tab3"
                  >
                    资料仓库暂未开放
                  </div>
                </v-slide-x-transition>
              </div>
              <div style="border: 1px solid #ccc" class="pa-2">
                <div style="color: #eb5454">注意</div>
                <div style="font-size: 12px">
                  1.所有策略和算法均基于历史数据，不代表未来股价走势<br />
                  2.回测结果仅供参考和研究，不构成投资建议<br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import StockPool from "@/api/stockPool.js";
import Strategy from "@/components/StockDetail/Strategy.vue";
import DailyLine from "@/components/StockDetail/DailyLine.vue";
import BackTest from "@/components/StockDetail/BackTest.vue";
import MultiBackTest from "@/components/StockDetail/MultiBackTest.vue";

export default {
  name: "Manage",
  components: { Strategy, DailyLine, BackTest, MultiBackTest },
  data() {
    return {
      main_tab: "1",
      showBackTest: false,
      showMultiBackTest: false,
      showPage: true,
      inPool: false,
    };
  },
  computed: {},
  beforeDestroy() {
    Bus.$off("showBackTest");
  },
  mounted() {
    //显示回测
    Bus.$on("showBackTest", (e) => {
      console.log("显示回测", e);
      this.showBackTest = false;
      this.showMultiBackTest = false;
      setTimeout(() => {
        this.showBackTest = true;
      }, 100);
    });

    //显示多策略回测
    Bus.$on("showMultiBackTest", (e) => {
      console.log("显示多策略回测", e);
      this.showBackTest = false;
      this.showMultiBackTest = false;
      setTimeout(() => {
        this.showMultiBackTest = true;
      }, 100);
    });

    this.$watch(
      () => this.appStore.currentStockDetail,
      (newValue, oldValue) => {
        console.log("切换到新股票");
        //刷新页面
        this.showPage = false;
        this.showBackTest = false;
        setTimeout(() => {
          this.isPool();
          this.showPage = true;
        }, 100);
      }
    );
    this.isPool();
  },
  methods: {
    isPool() {
      StockPool.isPool(this.appStore.currentStockDetail.stock_code)
        .then((res) => {
          this.inPool = res.data.has;
        })
        .catch((err) => {
          this.showToast(err.msg, "danger", 5000);
          console.log(err);
        });
    },
    deleteFromPool() {
      this.showLoading("删除中，请稍候");
      StockPool.deleteFromPool(this.appStore.currentStockDetail.stock_code)
        .then((res) => {
          console.log(res.data);
          this.showToast("删除成功！", "success", 5000);
          this.inPool = false;
          Bus.$emit("refreshCoreStockPool", true);
        })
        .catch((err) => {
          this.showToast(err.msg, "danger", 5000);
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    addToPool() {
      this.showLoading("添加中，请稍候");
      StockPool.addToPool(
        this.appStore.currentStockDetail.stock_code,
        this.appStore.currentStockDetail.code_type
      )
        .then((res) => {
          console.log(res.data);
          this.showToast("添加成功！", "success", 5000);
          this.inPool = true;
          Bus.$emit("refreshCoreStockPool", true);
        })
        .catch((err) => {
          this.showToast(err.msg, "danger", 5000);
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
