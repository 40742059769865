<template>
  <div class="mx-2">
    <div class="mb-1">
      <vxe-radio
        name="n1"
        v-model="isMultipleStrategy"
        label="1"
        content="单策略"
        size="mini"
      ></vxe-radio>
      <vxe-radio
        name="n2"
        v-model="isMultipleStrategy"
        label="2"
        content="混合策略"
        size="mini"
      ></vxe-radio>
    </div>
    <!-- 单策略 -->
    <div v-if="isMultipleStrategy == 1">
      <!-- 策略选择 -->
      <div>
        <span style="font-size: 13px" class="mr-2">选择策略</span>
        <vxe-select v-model="selectItem" placeholder="选择策略" size="mini">
          <vxe-option
            v-for="item in strategyList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          >
          </vxe-option>
        </vxe-select>
        <vxe-button
          class="px-1 py-0 ml-2"
          :status="paperShow ? 'my-danger' : 'my-primary'"
          @click="paperShow = !paperShow"
          size="mini"
          :content="paperShow ? '关闭策略资料' : '查看策略资料'"
        ></vxe-button>
      </div>
      <!-- 策略信息 -->
      <div style="font-size: 12px" class="mt-2">
        <div v-if="paperShow" class="mt-2">
          <!-- 策略资料 -->
          <div>
            <span>策略论文和相关资料：</span>
            <ul>
              <li v-for="paper in getPaperList">
                <a
                  @click="previewFile(paper.url, paper.title)"
                  target="_blank"
                  class="text-dark"
                >
                  {{ paper.title }}
                </a>
              </li>
            </ul>
          </div>
          <!-- 策略说明 -->
          <div>
            <span>策略说明：</span>
            <div
              style="color: #888"
              v-html="
                strategyList.filter((item) => item.id == selectItem)[0].intro
              "
            ></div>
          </div>
        </div>
        <!-- QRS(QRS支撑阻力策略) -->
        <div v-if="!paperShow" class="mt-2">
          <QRS
            v-if="this.selectItem == 'df7ed4af-7376-44da-a70a-c37c65c90687'"
          ></QRS>
          <LLT
            v-if="this.selectItem == '2eb23e3b-92b3-4a52-9e9f-830d9942c0e7'"
          ></LLT>
          <GridTrade
            v-if="this.selectItem == '82318ff9-81b8-466c-9674-afcd8dad5933'"
          ></GridTrade>
          <SupportResistance
            v-if="this.selectItem == 'd4535b8e-b305-48d5-93c6-17bff773e9c8'"
          ></SupportResistance>
          <TSI
            v-if="this.selectItem == 'b4ffcc91-e55f-4c2f-b938-034a9eb97bb2'"
          ></TSI>
          <WC
            v-if="this.selectItem == 'e6bd03ee-a6d9-4974-97d3-dc121be9f277'"
          ></WC>
          <RSI
            v-if="this.selectItem == 'e055b17c-e0f6-4e03-a713-38a340635533'"
          ></RSI>

          <!-- 历史回测结果 -->
          <div class="mt-2" style="border-top: 1px solid #ccc">
            <vxe-radio-group v-model="strategy_tab" size="mini" class="mt-2">
              <vxe-radio-button label="1" content="历史回测"></vxe-radio-button>
              <vxe-radio-button label="2" content="策略收藏"></vxe-radio-button>
            </vxe-radio-group>
            <v-slide-x-transition group hide-on-leave>
              <div v-if="strategy_tab == 1" key="strategy_tab1">
                <div class="mt-2">
                  历史回测结果<span class="text--secondary ml-2"
                    >(仅本地保存最近十条)</span
                  >
                  <a
                    class="ml-2"
                    href="javascript:void(0)"
                    @click="clearHistoryBackTest()"
                    style="color: #ef534f"
                    >清空</a
                  >
                </div>
                <div
                  v-for="item in historyBackTestArr"
                  class="my-1 py-2"
                  style="border-top: 1px solid #ccc"
                >
                  <div>
                    <span
                      style="
                        color: #ffffff;
                        background-color: #ef534f;
                        border-radius: 5px;
                        font-size: 11px;
                      "
                      class="px-1 py-1 text-truncate"
                      >{{ item.method ? item.method : item.name }}</span
                    >
                    <a
                      class="ml-1 indigo--text"
                      href="javascript:void(0)"
                      @click="showHistoryBackTest(item)"
                      >回放</a
                    >
                    <a
                      class="ml-1 indigo--text"
                      href="javascript:void(0)"
                      @click="saveHistoryBackTest(item)"
                      >收藏</a
                    >
                    <a
                      class="ml-1"
                      style="color: #ef534f"
                      href="javascript:void(0)"
                      @click="deleteHistoryBackTest(item)"
                      >删除</a
                    >
                  </div>
                  <div class="mt-1">
                    <span class="mr-1"
                      >累收:
                      <span
                        :style="
                          $stockHelpers.getColor(
                            item.report_table['策略']['累计收益']
                          )
                        "
                        >{{
                          $stockHelpers.getPercent(
                            item.report_table["策略"]["累计收益"]
                          )
                        }}</span
                      >
                    </span>
                    <span class="mr-1"
                      >年收:
                      <span
                        :style="
                          $stockHelpers.getColor(
                            item.report_table['策略']['年化收益率']
                          )
                        "
                        >{{
                          $stockHelpers.getPercent(
                            item.report_table["策略"]["年化收益率"]
                          )
                        }}</span
                      >
                    </span>
                    <span class="mr-1">
                      最大回撤:
                      <span
                        :style="
                          $stockHelpers.getColor(
                            item.report_table['策略']['最大回撤']
                          )
                        "
                        >{{
                          $stockHelpers.getPercent(
                            item.report_table["策略"]["最大回撤"]
                          )
                        }}
                      </span>
                    </span>
                    <span class="mr-1">
                      夏普:
                      <span>
                        {{
                          item.report_table["策略"]["夏普"]
                            ? item.report_table["策略"]["夏普"].toFixed(2)
                            : "-"
                        }}
                      </span>
                    </span>
                  </div>
                  <div class="text--secondary" style="font-size: 11px">
                    参数：
                    <span v-for="(option, optionIindex) in item.options">
                      {{ optionIindex }}:<u>{{ option }}</u
                      >，
                    </span>
                    <br />
                    回测区间：<u>{{ item.start_date }}</u> ~
                    <u>{{ item.end_date }}</u>
                  </div>
                </div>
              </div>
              <div v-if="strategy_tab == 2" key="strategy_tab2">
                <div class="mt-2">
                  收藏策略<span class="text--secondary ml-2"
                    >(设为默认策略可在股票池列表快速查看信号)</span
                  >
                  <a
                    class="ml-2"
                    href="javascript:void(0)"
                    @click="clearHistoryBackTest()"
                    style="color: #ef534f"
                    >清空</a
                  >
                </div>
                <div
                  v-for="item in savaedBackTestArr"
                  class="my-1 py-2"
                  style="border-top: 1px solid #ccc"
                >
                  <div>
                    <span
                      style="
                        border-radius: 5px;
                        font-size: 11px;
                        color: #ffffff;
                      "
                      :style="{
                        backgroundColor:
                          item.is_default == 1 ? '#ef534f' : '#888',
                      }"
                      class="px-1 py-1 text-truncate"
                      >{{
                        item.args.method ? item.args.method : item.args.name
                      }}</span
                    >
                    <a
                      class="ml-1 indigo--text"
                      href="javascript:void(0)"
                      @click="reTest(item)"
                      >回放</a
                    >
                    <a
                      class="ml-1 indigo--text"
                      href="javascript:void(0)"
                      @click="setDefaultSaved(item)"
                      >{{ item.is_default == 1 ? "已为默认" : "设为默认" }}</a
                    >
                    <a
                      class="ml-1"
                      style="color: #ef534f"
                      href="javascript:void(0)"
                      @click="deleteSaved(item)"
                      >删除</a
                    >
                  </div>
                  <div class="text--secondary mt-1" style="font-size: 11px">
                    参数：
                    <span v-for="(option, optionIindex) in item.args.options">
                      {{ optionIindex }}:<u>{{ option }}</u
                      >，
                    </span>
                    <br />
                    回测区间：<u>{{ item.args.optionsObj.start_date }}</u> ~
                    <u>{{ item.args.optionsObj.end_date }}</u>
                  </div>
                </div>
              </div>
            </v-slide-x-transition>
          </div>
        </div>
      </div>
    </div>
    <!-- 混合策略 -->
    <div v-if="isMultipleStrategy == 2">
      <MultipleStrategy></MultipleStrategy>
    </div>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import strategyStore from "@/assets/data/strategy.js";
import MultipleStrategy from "@/components/StockDetail/MultipleStrategy.vue";

import QRS from "@/components/StockDetail/strategy/QRS.vue";
import LLT from "@/components/StockDetail/strategy/LLT.vue";
import GridTrade from "@/components/StockDetail/strategy/GridTrade.vue";
import SupportResistance from "@/components/StockDetail/strategy/SupportResistance.vue";
import TSI from "@/components/StockDetail/strategy/TSI.vue";
import WC from "@/components/StockDetail/strategy/WC.vue";
import RSI from "@/components/StockDetail/strategy/RSI.vue";

export default {
  name: "Strategy",
  components: {
    MultipleStrategy,
    QRS,
    LLT,
    GridTrade,
    SupportResistance,
    TSI,
    WC,
    RSI,
  },
  data() {
    return {
      strategyList: strategyStore.strategyList,
      selectItem: "2eb23e3b-92b3-4a52-9e9f-830d9942c0e7",
      isMultipleStrategy: "1",
      paperShow: false,
      historyBackTestArr: [],
      savaedBackTestArr: [],
      strategy_tab: "1",
    };
  },
  computed: {
    getPaperList() {
      return this.strategyList.filter((item) => item.id == this.selectItem)[0]
        .paper;
    },
  },

  mounted() {
    //提取历史回测结果
    this.getSavedStrategy();
    if (
      localStorage.getItem(
        "singleStrategyBackTest" + this.appStore.currentStockDetail.stock_code
      )
    ) {
      this.historyBackTestArr = JSON.parse(
        localStorage.getItem(
          "singleStrategyBackTest" + this.appStore.currentStockDetail.stock_code
        )
      );
    }
    // 监听回测结果
    Bus.$on("showBackTest", (e) => {
      if (e) {
        let thisBackTest = this.appStore.backTestData;
        let historyBackTestArr = localStorage.getItem(
          "singleStrategyBackTest" + thisBackTest.stock_code
        );
        //记录进数组，历史记录只保存最新的10条
        if (historyBackTestArr) {
          historyBackTestArr = JSON.parse(historyBackTestArr);
          //检查是否已经有参数一样的回测结果
          let isSame = false;
          historyBackTestArr.forEach((item) => {
            if (JSON.stringify(item) == JSON.stringify(thisBackTest)) {
              isSame = true;
            }
          });
          if (!isSame) {
            historyBackTestArr.unshift(thisBackTest);
            if (historyBackTestArr.length > 10) {
              historyBackTestArr.pop();
            }
          } else {
            //如果有相同的，就把相同的删掉，然后把这个放到第一个
            historyBackTestArr = historyBackTestArr.filter((item) => {
              return JSON.stringify(item) != JSON.stringify(thisBackTest);
            });
            historyBackTestArr.unshift(thisBackTest);
          }
        } else {
          historyBackTestArr = [];
          historyBackTestArr.push(thisBackTest);
        }
        this.historyBackTestArr = historyBackTestArr;
        localStorage.setItem(
          "singleStrategyBackTest" + thisBackTest.stock_code,
          JSON.stringify(historyBackTestArr)
        );
      }
    });
  },
  methods: {
    previewFile(url, name) {
      Bus.$emit("previewFile", {
        url: url,
        name: name,
      });
    },
    showHistoryBackTest(item) {
      this.appStore.backTestData = item;
      Bus.$emit("showBackTest", false);
    },
    deleteHistoryBackTest(item) {
      let historyBackTestArr = JSON.parse(
        localStorage.getItem(
          "singleStrategyBackTest" + this.appStore.currentStockDetail.stock_code
        )
      );
      historyBackTestArr = historyBackTestArr.filter((historyItem) => {
        return JSON.stringify(historyItem) != JSON.stringify(item);
      });
      this.historyBackTestArr = historyBackTestArr;
      localStorage.setItem(
        "singleStrategyBackTest" + this.appStore.currentStockDetail.stock_code,
        JSON.stringify(historyBackTestArr)
      );
    },
    clearHistoryBackTest() {
      localStorage.removeItem(
        "singleStrategyBackTest" + this.appStore.currentStockDetail.stock_code
      );
      this.historyBackTestArr = [];
    },
    //获取收藏策略列表
    getSavedStrategy() {
      Stock.getSavedStrategy(this.appStore.currentStockDetail.stock_code)
        .then((res) => {
          res.data.forEach((item) => {
            item.args = JSON.parse(item.args);
          });
          this.savaedBackTestArr = res.data;
        })
        .catch((err) => {
          this.showToast(err.msg, "danger", 5000);
          console.log(err);
          //this.loginError();
        });
    },
    saveHistoryBackTest(item) {
      // console.log(item);
      // return;
      let options = {
        name: item.name,
        optionsObj: item.optionsObj,
        options: item.options,
      };
      if (item.method) {
        options.method = item.method;
      }
      this.showLoading("正在收藏中");
      Stock.saveStrategy(item.stock_code, item.id, JSON.stringify(options))
        .then((res) => {
          console.log(res);
          this.showToast("收藏成功", "success", 5000);
          this.getSavedStrategy();
        })
        .catch((err) => {
          this.showToast(err.msg, "danger", 5000);
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    reTest(item) {
      this.showLoading("正在回测中");
      let optionsObj = item.args.optionsObj;
      Stock.useStrategy(optionsObj).then((res) => {
        console.log(res);
        this.appStore.backTestData = {
          ...res.data,
          name: item.args.name,
          id: item.args.optionsObj.strategyId,
          stock_code: this.appStore.currentStockDetail.stock_code,
          stock_name: this.appStore.currentStockDetail.stock_name,
          start_date: item.args.optionsObj.start_date,
          end_date: item.args.optionsObj.end_date,
          options: item.args.options,
          optionsObj: optionsObj,
        };
        this.hideLoading();
        Bus.$emit("showBackTest", true);
      });
    },
    deleteSaved(item) {
      this.$layer.confirm("确定要删除该收藏吗？", (layerid) => {
        this.showLoading("正在删除中");
        Stock.deleteStrategy(item.id)
          .then((res) => {
            this.showToast("删除成功", "success", 5000);
            this.getSavedStrategy();
          })
          .catch((err) => {
            this.showToast(err.msg, "danger", 5000);
            console.log(err);
          })
          .finally(() => {
            this.hideLoading();
          });

        this.$layer.close(layerid);
      });
    },
    setDefaultSaved(item) {
      if (item.is_default) {
        this.showToast("已经是默认策略了", "danger", 5000);
        return;
      }
      this.showLoading("正在设置中");
      Stock.setDefaultStrategy(item.id)
        .then((res) => {
          this.showToast("设置成功", "success", 5000);
          this.getSavedStrategy();
        })
        .catch((err) => {
          this.showToast(err.msg, "danger", 5000);
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
